#toast-container-main {
    position: fixed;
    top: 5px;
    right: 50px;
    z-index: 9999999;
    display: flex;
    flex-direction: column;
}
  
  #toast-container-main > div {
    /* background-color: rgba(0, 0, 0, 0.15); */
    color: white;
    width: 100%;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    animation: slide-in 0.2s;
    transition: all 0.3s ease;
  }

  .modal-backdrop.show{
    opacity: 0.5;
  }

  .pointer{
    cursor: pointer;
  }


  /* IVR */
  .Treant.chart{
    overflow: visible;
  }
  /* End of IVR */

  .highlighted-row {
    background-color: #f0f8ff !important;
}


.button-spacing {
  margin-left: 10px;
  margin-bottom: 10px;
}
.button-group {
  margin-right: 15px; 
}

.custom-modal-footer .p-button {
  margin-left: 10px; /* Adjust the value as needed */
}

.increasePaddingBottom table {
  border-collapse: inherit;
  padding-bottom: 300px;
}

.removeMarker::marker{
  display: none;
}

/* Apply scrollbar styles to the container */
.scroll-container {
  overflow-y: auto;
  /* max-height: 400px;  */
  /* scrollbar-width: thin; */
  scrollbar-color: #007bff #f0f0f0;
}


/* WebKit-based browsers (Chrome, Edge, Safari) */
.scroll-container::-webkit-scrollbar {
  width: 20px !important;;
}

.scroll-container::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #0056b3;
}

.element.style {
  overflow-x: unset !important;
}

.p-datatable>.p-datatable-wrapper {
  overflow: unset !important;
}