.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link::after {
    display: none !important;
    border-bottom: none !important;
    content: "" !important;
    height: 0px !important;
  }
  
  .p-tabview .p-tabview-nav-link {
    padding: 10px 15px !important;  /* Adjust as needed */
    min-height: auto !important;
  }
  
/* Change active tab border color */
.custom-tab-container .p-tabview .p-tabview-nav li.p-highlight {
    border: 2px solid rgb(207, 205, 205) !important; /* Change border color */
    border-radius: 6px; /* Keep rounded corners */
  }
  
  /* Remove default bottom border */
  .custom-tab-container .p-tabview .p-tabview-nav {
    border-bottom: none !important;
  }

  /* Override PrimeReact Active Tab Border Color */
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border: none; /* Change active tab border to grey */
    box-shadow: none !important; /* Remove the default blue glow */
  }

.p-tabview .p-tabview-panels {
  padding: 0px;
}

.p-tabview .p-tabview-nav {
    width: 100%; /* Make sure the tab container spans full width */
    border-bottom: 2px solid #ddd !important; /* Adjust thickness & color */
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    display: none;
}
  
